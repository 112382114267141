<template>
  <div class="access-log-search-wrap" :class="{ expand }">
    <div v-if="expand" class="left-wrap">
      <div class="condition-wrap">
        <!-- 使用者/設備 -->
        <div v-if="permission" class="type-wrap">
          <div class="type-item" @click="onSelectUser">
            <img
              id="user"
              v-if="isUser"
              src="@/assets/icons/radio-check.svg"
              alt=""
            />
            <img id="user" v-else src="@/assets/icons/circle.svg" alt="" />
            <label for="user">{{ $t('log_account_type_user') }}</label>
          </div>
          <div class="type-item" @click="onSelectDevice">
            <img
              v-if="isDevice"
              id="device"
              src="@/assets/icons/radio-check.svg"
              alt=""
            />
            <img v-else id="device" src="@/assets/icons/circle.svg" alt="" />
            <label for="device">{{ $t('log_account_type_device') }}</label>
          </div>
        </div>
        <!-- 帳號 -->
        <template v-if="isUser">
          <div v-if="!permission" class="user-ids self">
            <div class="title">{{ $t('log_account_type_user') }}</div>
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :content="getLoginUserName"
              :disabled="false"
            >
              <span class="value">{{ getLoginUserName }}</span>
            </el-tooltip>
          </div>
          <TreeSelect
            v-else
            ref="userId"
            class="user-ids"
            v-model="userId"
            :title="$t('log_account_type_user')"
            :data="userTreeList"
            :groupList="groupList"
            :isGroupSelect="true"
            :isMultipleSelect="true"
            :placeholder="$t('log_search_placeholder_user')"
            optionWidth="150%"
          />
        </template>
        <TreeSelect
          v-if="isDevice"
          ref="userId"
          class="user-ids"
          v-model="userId"
          :title="this.$t('log_account_type_device')"
          :data="deviceTreeList"
          :groupList="groupList"
          :isGroupSelect="true"
          :isMultipleSelect="true"
          :placeholder="$t('log_search_placeholder_device')"
          optionWidth="150%"
        />
        <!-- 群組 -->
        <!-- <TreeSelect
          class="group"
          v-model="group"
          :title="$t('history_device_group') /*設備群組*/"
          :data="deviceGroupList"
          :groupList="groupList"
          :isGroupSelect="true"
          :isMultipleSelect="false"
          :defaultProps="deviceGroupProps"
          optionWidth="150%"
        /> -->
        <!-- 操作時間 -->
        <DateRangeSelect
          class="active-time"
          v-model="accessLogTime"
          :range="true"
          :title="$t('log_timestamp' /*操作時間*/)"
          :maxRangeDays="maxRangeDays"
          :enabledDateStart="enabledDateStart"
          :enabledDateEnd="enabledDateEnd"
          :shortcuts="shortcuts"
        />
        <!-- 動作 -->
        <ClassifySelect
          ref="action"
          class="action"
          :title="$t('action' /*動作*/)"
          :placeholder="$t('search_not_specified' /*未指定*/)"
          :disabled="false"
          v-model="action"
          :options="uiActionOptions"
          :multiple="true"
          :effect="'light'"
          :tooltipPlacement="'top'"
          @getFilterText="onGetActionFilterText"
        />
      </div>
      <div class="ctrl-btn-wrap">
        <MagnifierSearchBtn
          class="search"
          :active="searching"
          :disabled="searching"
          @click="onSearch"
        />

        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :content="$t('search_clear')"
          placement="top"
        >
          <SmallBtn
            class="eraser"
            :img="require('@/assets/icons/erase-white.svg')"
            :disabled="searching"
            @click="onEraser"
          />
        </el-tooltip>
      </div>
    </div>
    <div v-if="expand" class="empty"></div>
    <div v-if="expand" class="right-wrap"><!--R--></div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  // mapMutations,
  // mapActions
} from 'vuex'

import { mergeNameId } from '@/utils/'
import { getAllLastNodes } from '@/utils/lib'
import { PermissionLevel } from '@/config/permissions'
import { getLogDateRangeSetting } from '@/config/dateRange.js'
import { userLogCode, deviceLogCode, logCodeClass } from '@/config/codebook.js'

import { accountType } from '@/components/Log/AccessLog.vue'
import TreeSelect from '@/components/Base/TreeSelect.vue'
import DateRangeSelect from '@/components/Base/DateRangeSelect.vue'
import MagnifierSearchBtn from '@/components/Base/MagnifierSearchBtn.vue'
import SmallBtn from '@/components/Base/SmallBtn.vue'
import ClassifySelect from '@/components/Base/ClassifySelect.vue'

export default {
  name: 'AccessLogSearch',
  props: {
    expand: {
      type: Boolean,
      // default: false
    },
    searching: {
      type: Boolean,
      default: false
    },
    accTypes: {
      type: Array, // [Number] // 保留複選空間
      default() {
        return []
      }
    },
    isUser: {
      type: Boolean,
    },
    isDevice: {
      type: Boolean,
    },
  },
  components: {
    TreeSelect,
    DateRangeSelect,
    MagnifierSearchBtn,
    SmallBtn,
    ClassifySelect,
  },
  data() {
    return {
      userId: [], // 因為是 舊 API(PHP 版), 所以不是 userIds
      // group: [],
      accessLogTime: [], // [startTime, stopTime]
      maxRangeDays: 90, // 最大可選擇區間天數
      enabledDateStart: null, // 可以查詢開始時間
      enabledDateEnd: null, // 可以查詢結束時間

      action: [], // 因為是 舊 API(PHP 版), 所以不是 actions
      actionFilterText: ''
    }
  },
  computed: {
    ...mapState(['permissionV2', 'groupList']),
    ...mapState('account', ['userTreeList', 'deviceTreeList']),
    ...mapGetters(['getUserInfo']),
    permission() {
      return this.permissionV2.audit > PermissionLevel.L0 // true: 查別人; false: 查自己
    },
    shortcuts() {
      return [ // 快速選擇時間區間
        {
          text: this.$t('today'),
          onClick: () => {
            // 今天 00:00 ~ 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        },
        {
          text: this.$t('yesterday'),
          onClick: () => {
            // 昨天 00:00 ~ 昨天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        },
        {
          text: this.$t('last_week'),
          onClick: () => {
            // 7天前 00:00 ~ 今天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        },
        {
          text: this.$t('last_month'),
          onClick: () => {
            // 30天前 00:00 ~ 今天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 29)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        },
        {
          text: this.$t('last_three_months'),
          onClick: () => {
            // 過去三個月 00:00 ~ 今天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 89)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        }
      ]
    },
    logCode() {
      if (this.isDevice) {
        return deviceLogCode()
      }

      return userLogCode()
    },
    actionOptions() {
      let codes = this.logCode

      let options = []
      for (const value of Object.values(codes)) {
        const classify = logCodeClass(value)
        // console.log(`[actionOptions] value, classify`, value, classify)
        const existClassify = options.find(
          (_o) => _o.classify && _o.value === classify
        )

        if (!existClassify) {
          options.push({
            classify: true,
            label: this.$t(`log_codebook_${classify}`),
            value: classify,
            //   icon: img
          })
        }

        options.push({
          classify: false,
          label: this.$t(`log_codebook_${value}`),
          value,
          //   icon: img
        })
      }

      return options || []
    },
    uiActionOptions() {
      if (!this.actionFilterText) return this.actionOptions

      // 過濾
      // console.log(`[uiOptions] filterInput:`, this.filterInput)
      const key = this.actionFilterText.toLowerCase()
      // 1. 過濾類別
      const classifies = this.actionOptions
        .filter((_o) => _o.classify)
        .filter((_o) => _o.label.toLowerCase().includes(key))
      // console.log(`[uiOptions] classifies:`, classifies)

      // 2. 過濾 codebook
      const codes = this.actionOptions
        .filter((_o) => !_o.classify)
        .filter((_o) => _o.label.toLowerCase().includes(key))
      // console.log(`[uiOptions] codes:`, codes)

      // 3. codebook 類別, 取出完整類別
      const codebookClass = codes.map((_o) => logCodeClass(_o.value))
      const fullClass = this.actionOptions
        .filter((_o) => _o.classify)
        .filter((_o) => {
          return (
            codebookClass.includes(_o.value) ||
            classifies.map((_c) => _c.value).includes(_o.value)
          )
        })
      // console.log(`[uiOptions] fullClass:`, fullClass)

      // 4. 重整
      const fullClassValue = fullClass.map((_c) => _c.value)
      const codesValue = codes.map((_o) => _o.value)
      const options = this.actionOptions.filter((_o) => {
        if (_o.classify) {
          return fullClassValue.includes(_o.value)
        } else {
          return codesValue.includes(_o.value)
        }
      })

      return options
    },
    getLoginUserName() {
      if (this.getUserInfo) {
        return mergeNameId(this.getUserInfo.name, this.getUserInfo.id)
      }

      return null
    },
  },
  watch: {
    accTypes: {
      deep: true,
      handler(nVal, oVal) {
        const oType = oVal[0]
        const nType = nVal[0]

        if (oType !== nType) {
          this.userId = []
          this.action = [] // 因為 使用者＆設備 的 行為列表內容不同, 所以要清空
        }
      }
    }
  },
  methods: {
    // 資料
    init() {
      this.initDateRange()
    },
    initDateRange() {
      // 存取日誌預設搜尋區間: 預設1週
      // 存取日誌最大可搜尋區間:預設最近6個月
      // 存取日誌最遠可搜尋的範圍: 預設最近1年
      const {
        defaultDateRange,
        maxRangeDays,
        enabledDateStart,
        enabledDateEnd
      } = getLogDateRangeSetting()

      this.accessLogTime = defaultDateRange
      this.maxRangeDays = maxRangeDays
      this.enabledDateStart = enabledDateStart
      this.enabledDateEnd = enabledDateEnd
    },
    // 事件
    onSelectUser() {
      this.$emit('selectType', accountType.user)
    },
    onSelectDevice() {
      this.$emit('selectType', accountType.device)
    },
    onSearch() {
      let param = {
        startTime: new Date(this.accessLogTime[0]).toISOString(),
        stopTime: new Date(this.accessLogTime[1]).toISOString(),
      }

      let action = [...this.action.sort().map((_a) => `${_a}`)]
      if (action.length > 0) {
        param = {
          ...param,
          ...{ action },
        }
      } else {
        // 撈出所有 動作 去搜尋
        const defAction = Object.values(this.logCode).map((_a) => `${_a}`)
        // console.log(`[onSearch] defAction:`, defAction)
        param = {
          ...param,
          ...{ action: defAction },
        }
      }

      // 沒有權限就只能查自己(登入者)的帳號, 所以不用帶帳號
      if (this.permission) {
        if (this.userId.length > 0) {
          const userId = [...this.userId.map((_u) => `${_u.id}`)]
          param = {
            ...param,
            ...{ userId },
          }
        } else {
          // 撈出所有 使用者|設備 去搜尋
          if (this.isUser) {
            let userList = []
            getAllLastNodes(this.userTreeList, userList)
            if (userList.length > 0) {
              param = {
                ...param,
                ...{ userId: userList.map((_u) => _u.id) },
              }
            }
          } else if (this.isDevice) {
            let deviceList = []
            getAllLastNodes(this.deviceTreeList, deviceList)

            if (deviceList.length > 0) {
              param = {
                ...param,
                ...{ userId: deviceList.map((_u) => _u.id) },
              }
            }
          }
        }

        // if (this.group.length > 0) {
        //   const group = [...this.group.map((_u) => `${_u.id}`)]
        //   param = {
        //     ...param,
        //     ...{ group },
        //   }
        // }
      }

      this.$emit('search', param)

      this.onCloseDropdown()
    },
    onEraser() {
      this.onCloseDropdown()

      this.onSelectUser()
      this.userId = []
      this.initDateRange()
      this.action = []
    },
    onCloseDropdown() {
      this.$refs.action.onClickOutside()

      if (this.$refs.userId) {
        this.$refs.userId.onClickOutside()
      }
    },
    onGetActionFilterText(filterText) {
      this.actionFilterText = filterText
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
  },
}
</script>

<style lang="scss" scoped>
$GapW: px2rem(12);
* {
  box-sizing: border-box;
  // user-select: none;
}
.access-log-search-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 0.5rem;
  // padding-bottom: 0.5rem;
  color: $color_FFF;
  // background-color: $color_282942;

  &.expand {
    padding-bottom: 0.5rem;
  }

  .left-wrap {
    display: flex;
    margin-left: px2rem(40);
    width: 100%; // TODO

    .condition-wrap {
      display: flex;
      flex-wrap: wrap;
      // width: 100%;

      .type-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .type-item {
          display: flex;
          align-items: center;
          line-height: 1.5;
          cursor: pointer;
          // background-color: purple;

          img {
            width: 1.125rem;
            height: 1.125rem;
          }
          label {
            margin-left: 0.5rem;
            cursor: pointer;
          }
        }
      }

      .user-ids {
        margin-left: $GapW;
        width: 15rem;

        &.self {
          display: flex;
          flex-direction: column;
          margin-left: unset;
          // background-color: #f00;
          .title {
            font-size: 0.875rem;
          }
          .value {
            margin-top: 0.25rem;
            border-radius: 0.5rem;
            padding: 0 px2rem(12);
            width: 100%;
            height: px2rem(36);
            line-height: px2rem(36);
            color: $color_black;
            background-color: $color_FFF;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      .active-time {
        margin-left: $GapW;
        width: 20rem;
      }

      .action:deep {
        margin-left: $GapW;
        width: 10rem;
        height: px2rem(36);
        .dropdown-wrap {
          width: 15rem;
        }
      }
    }

    .ctrl-btn-wrap {
      display: flex;
      align-items: flex-end;
      margin-left: $GapW;
      // background-color: #0f0;
      .search {
        // margin-left: $GapW;
      }
      .eraser {
        margin-left: $GapW;
      }
    }
  }
  .right-wrap {
    margin-right: px2rem(40);
  }
}
</style>
