<template>
  <div class="access-log-table-wrap">
    <div class="result">{{ $t('search_result', { count: logTotal }) }}</div>
    <div class="table-wrap">
      <!-- 表頭 -->
      <div class="table-head">
        <!-- <div v-if="bDevMode" class="table-col header no">
          <span class="key">{{ '#No' }}</span>
        </div> -->

        <div class="table-col header userId">
          <button :disabled="disabledSort" @click="onSort('userId')">
            <span class="key">{{ operatorLabel }}</span>
            <img
              class="sort"
              :class="[sortClass('userId')]"
              src="@/assets/icons/Drop.svg"
              alt=""
            />
          </button>
        </div>
        <div class="table-col header targetId">
          <button :disabled="disabledSort" @click="onSort('targetId')">
            <span class="key">{{ $t('log_target' /*目標*/) }}</span>
            <img
              class="sort"
              :class="[sortClass('targetId')]"
              src="@/assets/icons/Drop.svg"
              alt=""
            />
          </button>
        </div>
        <div class="table-col header timestamp">
          <button :disabled="disabledSort" @click="onSort('timestamp')">
            <span class="key">{{ $t('log_timestamp' /*操作時間*/) }}</span>
            <img
              class="sort"
              :class="[sortClass('timestamp')]"
              src="@/assets/icons/Drop.svg"
              alt=""
            />
          </button>
        </div>
        <div class="table-col header action">
          <button :disabled="disabledSort" @click="onSort('action')">
            <span class="key">{{ $t('action' /*動作*/) }}</span>
            <img
              class="sort"
              :class="[sortClass('action')]"
              src="@/assets/icons/Drop.svg"
              alt=""
            />
          </button>
        </div>
        <div class="table-col header ip">
          <span class="key">{{ $t('log_ip' /*IP 位置*/) }}</span>
        </div>
        <div class="table-col header content" :disabled="false">
          <span class="key">{{ $t('log_content' /*內容*/) }}</span>
        </div>
      </div>
      <!-- 表內容 -->
      <div class="table-body">
        <!-- 表內容：搜尋中 -->
        <div v-if="searching" class="table-searching">
          <div class="loading"></div>
          <span>{{ $t('fr_table_searching' /*搜尋結果*/) }}</span>
        </div>
        <!-- 表內容：空 -->
        <div v-else-if="!uiLogs || uiLogs.length <= 0" class="table-empty">
          <span>{{
            logs === null ? $t('fr_table_empty_hint') : $t('search_hint_nodata')
          }}</span>
        </div>
        <template v-else>
          <div
            class="table-row"
            v-for="(log, idx) in uiLogs"
            :key="`${log.id}_${idx}`"
          >
            <!-- <div v-if="bDevMode" class="table-col no">
              <span>{{ JSON.stringify(log) }}</span>
            </div> -->

            <div class="table-col userId">
              <div class="value id-wrap">
                <div class="group">
                  <img src="@/assets/icons/group-icon.svg" alt="" />
                  <el-tooltip
                    popper-class="el-tooltip"
                    effect="dark"
                    v-delTabIndex
                    :visible-arrow="false"
                    :content="log.operator.groupName"
                  >
                    <span>{{ log.operator.groupName }}</span>
                  </el-tooltip>
                </div>
                <div class="account">
                  <el-tooltip
                    popper-class="el-tooltip"
                    effect="dark"
                    v-delTabIndex
                    :visible-arrow="false"
                    :content="log.operator.accLabel"
                  >
                    <span>{{ log.operator.accLabel }}</span>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="table-col targetId">
              <div class="value">
                <div class="value id-wrap">
                  <div class="group">
                    <img src="@/assets/icons/group-icon.svg" alt="" />
                    <el-tooltip
                      popper-class="el-tooltip"
                      effect="dark"
                      v-delTabIndex
                      :visible-arrow="false"
                      :content="log.target.groupName"
                    >
                      <span>{{ log.target.groupName }}</span>
                    </el-tooltip>
                  </div>
                  <div class="account">
                    <el-tooltip
                      popper-class="el-tooltip"
                      effect="dark"
                      v-delTabIndex
                      :visible-arrow="false"
                      :content="log.target.accLabel"
                    >
                      <span>{{ log.target.accLabel }}</span>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-col timestamp">
              <div class="value time-wrap">
                <span>{{ log.timestampYmd }}</span>
                <span>{{ log.timestampHms }}</span>
              </div>
            </div>
            <div class="table-col action">
              <span class="value">{{ $t(`log_codebook_${log.action}`) }}</span>
            </div>
            <div class="table-col ip">
              <span class="value">{{ log.ip }}</span>
            </div>
            <div class="table-col content">
              <span class="value">{{ log.content }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!searching && logTotal > 0" class="footer">
      <Pagination
        class="pagination"
        mode="multiple"
        :currentPage="currentPage"
        :total="logTotal"
        :pageSize="perPageCnt"
        @getPageData="onGetPageContents"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  mapState,
  // mapMutations,
  // mapActions
} from 'vuex'

import { mergeNameId } from '@/utils/'
import {
  formatTime,
  formatTimeNoSec,
  getNode,
  isDevMode,
} from '@/utils/lib.js'

import Pagination from '@/components/Base/Pagination.vue'

const euSort = {
  none: 0,
  asc: 1,
  desc: -1
}

export default {
  name: 'AccessLogTable',
  props: {
    searching: {
      type: Boolean,
      default: false
    },
    logs: {
      type: Array,
      default: null // []
    },
    isUser: {
      type: Boolean,
    },
    isDevice: {
      type: Boolean,
    },
  },
  components: {
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      sort: {
        key: null,
        st: 0, // 0:none, 1:asc, -1:desc
      },
    }
  },
  computed: {
    ...mapState('account', ['groupList', 'userTreeList', 'deviceTreeList']),
    bDevMode() {
      return isDevMode()
    },
    operatorLabel() {
      if (this.isUser) {
        return this.$t('log_account_type_user' /*使用者*/)
      } else if (this.isDevice) {
        return this.$t('log_account_type_device' /*設備*/)
      }
      return this.$t('log_operator' /*操作人*/)
    },
    uiLogs() {
      let logList = JSON.parse(JSON.stringify(this.logs)) || []

      // 只留下該 currentPage 的 logs
      logList = logList.splice(
        (this.currentPage - 1) * this.perPageCnt,
        this.perPageCnt
      )

      // 擴增資訊
      logList = logList.map((log) => {
        const operator = this.getAccount(log.userId)
        const target = this.getAccount(log.targetId)
        // console.log(`[uiLogs] log:`, log)
        // console.log(`[uiLogs] operator:`, operator)
        // console.log(`[uiLogs] target:`, target)

        // timestamp
        let timestampYmd, timestampHms
        if (this.bDevMode) {
          const [ymd, hms] = formatTime(log.timestamp).split(' ')

          timestampYmd = ymd
          timestampHms = hms
        } else {
          const [ymd, hms] = formatTimeNoSec(log.timestamp).split(' ')

          timestampYmd = ymd
          timestampHms = hms
        }

        return {
          ...log,
          ...{
            operator: {
              accLabel: mergeNameId(operator.name, operator.id) || log.userId,
              groupName: this.groupList.find((_g) => _g.id === operator.groupId).name
            },
            target: {
              accLabel: mergeNameId(target.name, target.id) || log.targetId,
              groupName: this.groupList.find((_g) => _g.id === target.groupId).name
            },
            timestampYmd,
            timestampHms,
          }
        }
      })

      if (!this.sort.key) return logList

      // 排序 ---
      const { key, st } = this.sort
      if (key === 'timestamp') {
        if (st === euSort.asc) { // new -> old
          logList.sort((a, b) => moment(b[key]).diff(a[key]))
        } else if (st === euSort.desc) { // old -> new
          logList.sort((a, b) => moment(a[key]).diff(b[key]))
        }
      } else {
        if (st === euSort.asc) { // A -> Z
          logList.sort((a, b) => {
            if (a[key] < b[key]) return -1
            else if (a[key] > b[key]) return 1
            else return 0
          })
        } else if (st === euSort.desc) { // Z -> A
          logList.sort((a, b) => {
            if (a[key] < b[key]) return 1
            else if (a[key] > b[key]) return -1
            else return 0
          })
        }
      }

      return logList
    },
    logTotal() {
      if (!this.logs) return 0

      return this.logs.length
    },
    perPageCnt() {
      return 30 // ref: 車號管理>使用紀錄
    },
    disabledSort() {
      return this.uiLogs.length <= 0
    }
  },
  watch: {
    searching(nVal, oVal) {
      // 搜尋完 要回到第一頁
      if (!nVal && oVal) {
        this.currentPage = 1
      }
    }
  },
  methods: {
    // 資料
    getAccount(logUserId) {
      let acc = getNode(this.userTreeList, logUserId)

      if (!acc) {
        acc = getNode(this.deviceTreeList, logUserId)
      }

      return acc
    },
    // 事件
    onSort(key) {
      // 原始 >> 升冪(^ : A->Z, 新->舊) >> 降冪(v : Z->A, 舊->新) >> 原始 ...
      if (key === this.sort.key) {
        if (this.sort.st === euSort.none) {
          this.sort = { key, st: euSort.asc }
        } else if (this.sort.st === euSort.asc) {
          this.sort = { key, st: euSort.desc }
        } else {
          this.sort = { key, st: euSort.none }
        }
      } else {
        this.sort = { key, st: euSort.asc }
      }
    },
    onGetPageContents(page) {
      // console.log(`[AccessLogTable.onGetPageContents]page:`, page)
      if (this.currentPage !== page) {
        this.currentPage = page
      }
    },
    sortClass(key) {
      if (key === this.sort.key) {
        if (this.sort.st === euSort.desc) return 'desc'
        else if (this.sort.st === euSort.asc) return 'asc'
        else return ''
      } else {
        return ''
      }
    }
  },
  mounted() {},
  beforeDestroy() {},
}
</script>

<style lang="scss" scoped>
$BDR_RADIUS: px2rem(3);
$TableW: 1150;
$ResultH: px2rem(24);
$FooterH: px2rem(65);

* {
  box-sizing: border-box;
  // user-select: none;
}

.access-log-table-wrap {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  width: 100%;
  height: 100vh;
  color: $color_FFF;
  background-color: unset;

  .result {
    width: 100%;
    height: $ResultH;
    text-align: end;
    color: $color_FFF;
  }

  .table-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    border-radius: $BDR_RADIUS;
    width: 100%;
    flex-grow: 1;

    .table-head {
      display: flex;
      border-top-left-radius: $BDR_RADIUS;
      border-top-right-radius: $BDR_RADIUS;
      // padding-left: px2rem(20);
      padding-right: px2rem(12);
      width: 100%;
      // font-size: 0.875rem; // 14px
      background-color: $color_4A5C78;
    }

    .table-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      // margin-right: calc(100vw - 100%);
      border-bottom-left-radius: $BDR_RADIUS;
      border-bottom-right-radius: $BDR_RADIUS;
      height: px2rem(200); // DEBUG
      overflow-y: auto;

      &:has(.table-row) {
        background-color: $color_151B35;
        scrollbar-gutter: stable;
      }

      .table-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: $BDR_RADIUS;
        border-bottom-right-radius: $BDR_RADIUS;
        width: 100%;
        height: px2rem(90);
        color: $color_FFF_50;
        background-color: $color_151B35;
      }

      .table-searching {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: $BDR_RADIUS;
        border-bottom-right-radius: $BDR_RADIUS;
        width: 100%;
        height: px2rem(90);
        color: $color_FFF_50;
        background-color: $color_151B35;
        .loading {
          @include loading(1rem, $color_FFF);
        }
        span {
          margin-left: 0.5rem;
        }
      }

      .table-row {
        display: flex;
        padding: px2rem(8) 0 px2rem(8) 0;
        border-bottom: 1px solid $color_4A5C78;
        width: 100%;

        &:last-child {
          border-bottom: unset;
        }

        &:hover {
          background-color: $color_4A5C78_40;
        }
      }
    }

    $ColGapW: 20;
    .table-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: px2rem($ColGapW);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      // background-color: #00f;

      // &:hover:has(.sort) {
      //   cursor: pointer;
      // }
      &:disabled:has(.sort) {
        cursor: unset;

        // .sort {
        //   @include disabled;
        // }
      }
      &.header {
        font-size: 0.875rem; // 14px
      }
      button[disabled] {
        img {
          @include disabled;
        }
      }

      .checkbox {
        width: 1rem;
        height: 1rem;
      }
      .key {
        text-align: center;
        color: $color_FFF;
        // width: 100%;
      }
      .sort {
        margin-left: 0.5rem;
        width: 1rem;
        height: 1rem;
        @include filter_6E7D93;
        transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);

        &.asc {
          @include filter_FFC600;
          transform: rotate(180deg);
        }

        &.desc {
          @include filter_FFC600;
        }
      }

      .value {
        color: $color_FFF;

        // text-overflow: ellipsis;
        // white-space: nowrap;
        // overflow: hidden;
      }

      $ColW120: 120;

      $UserIdW: calc($ColW120 * 1.5);
      $TargetIdW: calc($ColW120 * 1.5);
      $TimestampW: $ColW120;
      $ActionW: $ColW120;
      $IpW: $ColW120;

      $ContentW: calc($TableW - $UserIdW - $TargetIdW - $TimestampW - $ActionW - $IpW - 7 * $ColGapW);
      &.userId {
        min-width: calc($UserIdW / $TableW * 100%);
      }
      &.targetId {
        min-width: calc($TargetIdW / $TableW * 100%);
      }

      .id-wrap {
        width: 100%;
        // background-color: #f00;
        .group,
        .account {
          display: flex;
          align-items: center;
          width: 100%;
        }
        .group {
          @include font_bold;
          color: $color_group;
          span {
            margin-left: 0.5rem;
          }
        }
        img {
          width: 1rem;
          height: 1rem;
        }
        span {
          // text-wrap: wrap;
          // word-break: break-all;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &.timestamp {
        min-width: calc($TimestampW / $TableW * 100%);

        .time-wrap {
          display: flex;
          flex-direction: column;
        }
      }
      &.action {
        min-width: calc($ActionW / $TableW * 100%);
      }
      &.ip {
        min-width: calc($IpW / $TableW * 100%);
      }
      &.content {
        min-width: calc($ContentW / $TableW * 100%);
      }
    }
  }

  .footer:deep {
    display: flex;
    justify-content: center;
    height: $FooterH;
    color: $color_FFF;
    // background-color: #0f0;
    .pagination {}
  }
}
</style>
