export const logLevel = {
  debug: 0, // 除錯
  fatal: 1, // 重大
  error: 2, // 錯誤
  warn: 3, // 警告
  info: 4 // 資訊
}

const logCode = {
  // 登入登出 ---
  login_ok: 1,
  login_fail: 2,

  // 群組 ---
  create_group: 101,
  edit_group: 102,
  delete_group: 103,
  // create_group_license: 104,
  // edit_group_license: 105,
  // delete_group_license: 106,

  // 使用者 ---
  create_user: 201,
  edit_user: 202,
  delete_user: 203,
  // create_user_license: 204,
  // edit_user_license: 205,
  // delete_user_license: 206,
  // verify_user_email: 207,
  unlock_user_account: 208,
  lock_user_account: 209,

  // 設備 ---
  create_device: 301,
  edit_device: 302,
  delete_device: 303,

  // 伺服器 ---
  create_server: 401,
  edit_server: 402,
  delete_server: 403,

  // 直播 ---
  live_ok: 501,
  live_fail: 502,

  // 觀看 ---
  view_ok: 601,
  view_fail: 602,

  // 歷史影像 ---
  create_video: 701,
  edit_video: 702,
  delete_video: 703,
  playback_video_ok: 704,
  playback_video_fail: 705,
  download_video_ok: 706,
  download_video_fail: 707,

  // // [legacy] Jager Eye config ---
  // create_jager_eye_config: 801,
  // edit_jager_eye_config: 802,
  // delete_jager_eye_config: 803,
  // enable_jager_eye_config: 804,
  // disable_jager_eye_config: 805,
  // edit_jager_eye_event: 806,
  // delete_jager_eye_evnet: 807,

  // 人辨 ---
  create_bovia_fr_config: 901,
  edit_bovia_fr_config: 902,
  delete_bovia_fr_config: 903,

  // 設備控制 ---
  device_ctrl_ok: 1001,
  device_ctrl_fail: 1002,

  // 遠端控制 ---
  remote_ctrl_ok: 1101,
  remote_ctrl_fail: 1102,

  // 車牌辨識 ---
  create_lpr_config: 1201,
  edit_lpr_config: 1202,
  delete_lpr_config: 1203,
  create_lpr_vehicle: 1204,
  edit_lpr_vehicle: 1205,
  delete_lpr_vehicle: 1206,
  edit_lpr_snapshot: 1207,
  delete_lpr_snapshot: 1208,
  edit_lpr_event: 1209,
  delete_lpr_event: 1210,
  create_lpr_tag: 1211,
  edit_lpr_tag: 1212,
  delete_lpr_tag: 1213,

  // 角色 ---
  create_user_role: 1301,
  edit_user_role: 1302,
  delete_user_role: 1303,

  // 物件辨識事件 ---
  edit_or_event: 1401,
  delete_or_event: 1402,

}

export const userLogCode = () => {
  const {
    login_ok,
    login_fail,

    create_group,
    edit_group,
    delete_group,

    create_user,
    edit_user,
    delete_user,
    // unlock_user_account,
    // lock_user_account,

    create_device,
    edit_device,
    delete_device,

    // create_user_role,
    // edit_user_role,
    // delete_user_role,
  } = logCode
  return {
    login_ok,
    login_fail,

    create_group,
    edit_group,
    delete_group,

    create_user,
    edit_user,
    delete_user,
    // unlock_user_account,
    // lock_user_account,

    create_device,
    edit_device,
    delete_device,

    // create_user_role,
    // edit_user_role,
    // delete_user_role,
  }
}

export const deviceLogCode = () => {
  const {
    login_ok,
    login_fail,

    edit_device,

    // live_ok,
    // live_fail,
  } = logCode

  return {
    login_ok,
    login_fail,

    edit_device,

    // live_ok,
    // live_fail,
  }
}

// 方法
export function logCodeClass(code) {
  let ret = 0

  const code1 = Math.floor(Number(code) / 100)
  if (code1 > 0) {
    ret = code1 * 100
  } else {
    const code2 = Math.floor(Number(code) / 10)
    if (code2 > 0) {
      ret = code2 * 10
    }
  }

  return ret
}
