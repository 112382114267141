import { getDefaultDateRange, genFormattedDatetime } from '@/utils/lib.js'

// 車牌ㄧ般查詢區間
const lprBasicDateRange = {
  defaultRangeDays: 180, // 預設區間天數
  maxRangeDays: 180, // 最大搜尋範圍(天)
  recentEnabledDays: 365 // 最早可搜尋天數, 0: 不限制
}

// 車牌進階查詢區間
const lprAdvDateRange = {
  defaultRangeDays: 7, // 預設區間天數
  maxRangeDays: 180, // 最大搜尋範圍(天)
  recentEnabledDays: 365 // 最早可搜尋天數, 0: 不限制
}

// 人物查詢區間
const frDateRange = {
  defaultRangeDays: 7, // 預設區間天數
  maxRangeDays: 180, // 最大搜尋範圍(天)
  recentEnabledDays: 365 // 最早可搜尋天數, 0: 不限制
}

// 物件查詢區間
const orDateRange = {
  defaultRangeDays: 7, // 預設區間天數
  maxRangeDays: 180, // 最大搜尋範圍(天)
  recentEnabledDays: 365 // 最早可搜尋天數, 0: 不限制
}

// 緊急事件查詢區間
const urgentDateRange = {
  defaultRangeDays: 7, // 預設區間天數
  maxRangeDays: 90, // 最大搜尋範圍(天)
  recentEnabledDays: 180 // 最早可搜尋天數, 0: 不限制
}

const videoDateRange = {
  defaultRangeDays: 7, // 預設區間天數
  maxRangeDays: 180, // 最大搜尋範圍(天)
  recentEnabledDays: 365 // 最早可搜尋天數, 0: 不限制
}

const dashboardVideoDateRange = {
  ...videoDateRange,
  ...{ defaultRangeDays: 3 } // 預設區間天數
}

// 日誌查詢區間
const logDateRange = { ...orDateRange }

function getDateRangeSetting(params) {
  // 預設區間 ex: [start, end]
  const defaultDateRange = getDefaultDateRange(params.defaultRangeDays)
  // 最大可搜尋區間 ex: 180天 --> 一次最多搜尋180天
  const maxRangeDays = params.maxRangeDays
  // 最早可搜尋天數 ex: 365天 --> 只能搜尋一年內的資料，日期是從今天往前推算365天
  // 0: 不限制
  const enDays = params.recentEnabledDays
  const enabledDateStart = enDays === 0 ? null : genFormattedDatetime(-enDays, 'days')
  const enabledDateEnd = enDays === 0 ? null : genFormattedDatetime(1, 'days') // 包含今天
  return {
    defaultDateRange,
    maxRangeDays,
    enabledDateStart,
    enabledDateEnd
  }
}

export function getLprBasicDateRangeSetting() {
  return getDateRangeSetting(lprBasicDateRange)
}

export function getLprAdvDateRangeSetting() {
  return getDateRangeSetting(lprAdvDateRange)
}

export function getFrDateRangeSetting() {
  return getDateRangeSetting(frDateRange)
}

export function getOrDateRangeSetting() {
  return getDateRangeSetting(orDateRange)
}

export function getUrgentDateRangeSetting() {
  return getDateRangeSetting(urgentDateRange)
}

export function getVideoDateRangeSetting() {
  return getDateRangeSetting(videoDateRange)
}

export function getDashboardVideoDateRangeSetting() {
  return getDateRangeSetting(dashboardVideoDateRange)
}

export function getLogDateRangeSetting() {
  return getDateRangeSetting(logDateRange)
}
