<template>
  <div class="access-log-wrap" :class="{ full: !expand }">
    <AccessLogSearch
      :expand="expand"
      :searching="searching"
      :accTypes="accTypes"
      :isUser="isUser"
      :isDevice="isDevice"
      @search="onSearch"
      @selectType="onSelectAccType"
    />
    <ExpandLine
      class="expand-line"
      :expand="expand"
      :disabled="searching"
      @click="$emit('expand')"
    />
    <AccessLogTable
      :searching="searching"
      :logs="logs"
      :isUser="isUser"
      :isDevice="isDevice"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import { apiCheckStatus, apiErrorMsg, apiPostGroupLogSearchAccess } from '@/api'

import ExpandLine from '@/components/Base/ExpandLine.vue'
import AccessLogSearch from '@/components/Log/access/AccessLogSearch.vue'
import AccessLogTable from '@/components/Log/access/AccessLogTable.vue'

export const accountType = {
  user: 1,
  device: 2
}

export default {
  name: 'AccessLog',
  components: {
    ExpandLine,
    AccessLogSearch,
    AccessLogTable
  },
  props: {
    expand: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searching: false,
      logs: null,
      accTypes: [accountType.user]
    }
  },
  computed: {
    ...mapState([]),
    isUser() {
      return this.accTypes.includes(accountType.user)
    },
    isDevice() {
      return this.accTypes.includes(accountType.device)
    }
  },
  watch: {},
  methods: {
    ...mapMutations([]),
    ...mapActions([]),

    // 事件
    async onSearch(param) {
      this.searching = true
      // console.log(`[AccessLog.onSearch] param:`, param)
      this.$nextTick(async () => {
        if (this.searching) {
          try {
            const res = await apiPostGroupLogSearchAccess(param)

            if (!apiCheckStatus(res)) throw res
            // console.log(`[AccessLog.onSearch] res.data:`, res.data)
            this.logs = JSON.parse(JSON.stringify(res.data))
          } catch (err) {
            this.$notify({
              type: 'error',
              title: this.$t('search_fail_title'), //'搜尋失敗',
              message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error')
            })
          } finally {
            this.searching = false
          }
        }
      })
    },
    onSelectAccType(type) {
      if (!this.accTypes.includes(type)) {
        this.accTypes = [type]
        this.logs = null
      }
    }
  },
  mounted() {},
  beforeDestroy() {},
}
</script>

<style lang="scss" scoped>
.access-log-wrap {
  display: flex;
  flex-direction: column;
  height: calc(100vh - px2rem($NavH));
  width: 100%;
  background-color: $color_282942;
  &.full {
    height: 100%;
  }
}
</style>
